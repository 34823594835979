import { useState, useEffect, useRef } from 'react';

const VideoPlayer = () => {
  const playerRef = useRef(null);

  useEffect(() => {
    if (playerRef.current && typeof window !== "undefined") {
      window.jwplayer(playerRef.current).setup({
        playlist: "https://cdn.jwplayer.com/v2/playlists/JU6s0c6m?format=json",
        width: '100%',
        aspectratio: '16:9',
        nextUpDisplay: false,
        autostart: false
      });
    }
    window.jwplayer().on('beforeComplete', () => { window.jwplayer().stop() } );
  }, []);

  return <div ref={playerRef}></div>;
};

export default VideoPlayer;