import TMG_LOGO from "../assets/TMG-Vert-Logo-Web.png";
import CMF_LOGO from "../assets/CMF-Logo-White-Web.png";
import VIVA_LOGO from "../assets/VIVA-logo.webp";
import "../styles/Footer.css";
import { FaFacebook, FaInstagram, FaSquareFacebook } from 'react-icons/fa6'

export default function Footer() {
    return (
        <div id="footer">
            <div className="footer-section">
                <p>A TLN MEDIA GROUP PRODUCTION</p>
                <a href="https://www.tln.ca">
                    <img src={ TMG_LOGO } alt="TLN Media Group Logo" width="123px" height="120px"></img>
                </a>
            </div>
            <div className="footer-section">
                <p>WITH THE SUPPORT OF</p>
                <a href="https://cmf-fmc.ca/">
                    <img src={ CMF_LOGO } alt="Canadian Media Fund Logo" width="156px" height="120px"></img>
                </a>
            </div>
            <div className="footer-section">
                <a href="https://landing.vivatv.ca/">
                    <img src={ VIVA_LOGO } alt="VivaTV Logo" style= {{marginLeft: 0}} width="156px" height="70px"></img>
                </a>
            </div>
            <div className="footer-section">
                <a href="https://www.instagram.com/tln_studios/"><FaInstagram aria-label="Instagram logo"/></a>
            </div>
            <div className="footer-section">
                <a href="https://www.facebook.com/TLNTelevision"><FaFacebook aria-label="Facebook logo"/></a>
            </div>
            <div className="footer-section">
                <p>#EmergeTheSeries</p>
            </div>
        </div>
    )
}