import "../styles/Gallery.css";
import WOOD from "../assets/wood.webp";

export default function Gallery() {
    return (
        <div id="gallery-landing">
            <h1>IMAGE GALLERY</h1>
            <div id="polaroid-wrapper">
                <a href="/gallery/hamilton" className="polaroid">
                        <div className="vignette">
                            <img src="/assets/webp/hamilton/emerge-bts-hamilton-1.webp" alt="man walking down hallway"></img>                    
                        </div>
                        <h2>Hamilton</h2>
                        <p>(Auchmar)</p>
                </a>
                <a href="/gallery/fergus" className="polaroid">
                        <div className="vignette">
                            <img src="/assets/webp/fergus/emerge-bts-fergus-27.webp" alt="woman in spotlight"></img>                    
                        </div>
                        <h2>Fergus</h2>
                </a>
                <a href="/gallery/toronto" className="polaroid">
                        <div className="vignette">  
                            <img src="/assets/webp/toronto/emerge-bts-toronto-9.webp" alt="camera man on rooftop"></img>                    
                        </div>
                        <h2>Toronto</h2>
                </a>
            </div>
            <div id="wood-background" style={{backgroundImage: 'url(' + WOOD + ')'}}></div>
        </div>
    )
}