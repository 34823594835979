import { useState, useEffect, useRef } from 'react';

const VideoPlayer = () => {
  const playerRef = useRef(null);

//   const [video, setVideo] = useState();

  useEffect(() => {
    if (playerRef.current && typeof window !== "undefined") {
      window.jwplayer(playerRef.current).setup({
        playlist: "https://cdn.jwplayer.com/v2/playlists/lAO6C8d0?format=json",
        width: '100%',
        aspectratio: '16:9',
        nextUpDisplay: false,
      });
    }

    window.jwplayer().on('beforeComplete', () => { window.jwplayer().stop() } );
    
  }, []);

  return <div ref={playerRef}></div>;
};

export default VideoPlayer;