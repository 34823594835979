import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Episodes from "./pages/Episodes";
import Interviews from "./pages/Interviews";
import Gallery from "./pages/Gallery";
import Hamilton from "./pages/Hamilton";
import Toronto from "./pages/Toronto";
import Fergus from "./pages/Fergus";
import Cast from "./pages/Cast";
import NoMatch from "./pages/NoMatch";
import Watch from "./pages/Watch";

export default function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/episodes" element={<Episodes />} />
          <Route path="/behind-the-scenes" element={<Interviews />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/gallery/toronto" element={<Toronto />} />
          <Route path="/gallery/fergus" element={<Fergus />} />
          <Route path="/gallery/hamilton" element={<Hamilton />} />
          <Route path="/meet-the-cast" element={<Cast />} />
          <Route path="/where-to-watch" element={<Watch />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </>
  );
}