import '../styles/Interviews.css';
import InterviewList from "../data/interviews.json";
import InterviewPlayer from '../components/InterviewPlayer';
import { FaCirclePlay } from "react-icons/fa6";
import video from '../assets/Website Background Master4.webm';
import { useState, useRef } from 'react';


export default function Interviews() {
    const interviews = InterviewList.interviews;
    const [interview, setInterview] = useState(interviews[0]);
    const playerRef = useRef([]);
  
    const handleClick = (e) => {
      e.preventDefault();
      setInterview(interviews[e.target.value]);
      window.jwplayer().playlistItem(e.target.value);
      playerRef.current.scrollIntoView({ behavior: "smooth", alignToTop: true });
    }
  
    function InterviewSelectors() {
  
      const buttons = interviews.map((button, index) => {
        return <button 
            key={index}
            className={interviews.indexOf(interview) === index ? "active-interview" : "" } 
            value={index} 
            onClick={handleClick}>
            <img src={ button.thumbnail } loading="lazy" alt="video thumbnail"></img>
            { button.title }
        </button>
      });
  
      return (
        <>
          <div id="interview-selector">
            { buttons }
          </div>
        </>
      );
    }
  
    return (
      <>
        <video id="interview-background" autoPlay loop muted>
          <source src={video} type="video/mp4"></source>
        </video>
        <div id="interviews" ref={ playerRef }>
          <div id="interview-wrapper">
              <div id="interview-video">
                  <InterviewPlayer />
              </div>
              <div id="synopsis">
                  <h1>{interview.title}</h1>
                  <p>{interview.synopsis}</p>
              </div>
          </div>
          <InterviewSelectors />
        </div>
      </>
    );
  }