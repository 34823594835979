import { useEffect, useRef, useState } from 'react';
import { FaCaretLeft, FaCaretRight, FaArrowRight, FaAngleLeft } from "react-icons/fa6";
import GalleryJSON from "../data/hamilton-gallery.json";
import "../styles/Gallery.css";
import PAPER_TEXTURE from "../assets/paper-texture.webp";
import video from '../assets/Website Background Master4.webm';

export default function Hamilton() {
    const gallery = GalleryJSON.gallery;
    const carouselRef = useRef();
    const thumbnailsRef = useRef([]);
    const galleryRef = useRef([]);
    const scrollHintRef = useRef([]);
    const descriptionRef = useRef();

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        carouselRef.current.children[0].classList.add("active-thumb");
        galleryRef.current[0].classList.add("active-gallery");

        carouselRef.current.onscroll = hideScrollHint;
        descriptionRef.current.innerHTML = gallery[0].description;
    }, [])

    const hideScrollHint = () => {
        scrollHintRef.current.style.color = 'var(--bg)';
    }

    const handleClick = (e) => {
        e.preventDefault();
        setActive(parseInt(e.target.getAttribute("index")));
    }

    function setActive(index) {
        console.log(currentIndex);
        setCurrentIndex(index);

        for (let i = 0; i < carouselRef.current.children.length; i++) {
            carouselRef.current.children[i].classList.remove("active-thumb");
            galleryRef.current[i].classList.remove("active-gallery");
        }
        carouselRef.current.children[index].classList.add("active-thumb");
        galleryRef.current[index].classList.add("active-gallery");
        descriptionRef.current.innerHTML = gallery[index].description;
    }

    function slideCarousel(direction) {
        let thumbWidth = thumbnailsRef.current[0].offsetWidth + 20;
        let carouselWidth = carouselRef.current.offsetWidth;
        let distance = carouselWidth - (carouselWidth % thumbWidth);
        let currentPos = carouselRef.current.scrollLeft;
        carouselRef.current.scrollTo({
            top: 0,
            left: currentPos + (direction * distance),
            behavior: 'smooth'
        })
    }

    function slideGallery(direction) {
        let newIndex = currentIndex + direction
        console.log(newIndex);
        if( newIndex < 0 || newIndex == carouselRef.current.children.length ) {
            return;
        } else {
            let thumbWidth = thumbnailsRef.current[0].offsetWidth + 20;
            let currentPos = carouselRef.current.scrollLeft;
            carouselRef.current.scrollTo({
                top: 0,
                left: currentPos + (direction * thumbWidth),
                behavior: 'smooth'
            })
            setActive(newIndex);
        }
    }

    return (
        <>
            <video id="interview-background" autoPlay loop muted>
                <source src={video} type="video/mp4"></source>
            </video>
            <a href="/gallery" id="back-to-gallery"><FaAngleLeft /> BACK TO ALL GALLERIES</a>
            <div id="gallery-wrapper">
                <button id="gallery-left" onClick={ () => slideGallery(-1) }><FaCaretLeft /></button>
                {gallery.map((thumbnail, index) =>
                    <img src={ thumbnail.imageSrc } alt={ thumbnail.description } key={ index } ref={ (element) => galleryRef.current.push(element) } loading={(index < 3) ? "eager" : "lazy"}></img>
                    )}
                <button id="gallery-right" onClick={ () => slideGallery(1) }><FaCaretRight /></button>
            </div>

            <h2 id="gallery-description" ref={ descriptionRef } style={{backgroundImage: `url(${PAPER_TEXTURE})`}}></h2>

            <div id="gallery-carousel">
                <button onClick={ () => slideCarousel(-1) }><FaCaretLeft /></button>
                <div id="thumbnails-wrapper" ref={ carouselRef }>
                    {gallery.map((thumbnail, index) =>
                        <div 
                            key={ index }
                            onClick={ handleClick } 
                            index={ index } 
                            className='thumbnail'
                            ref={ (element) => thumbnailsRef.current.push(element) }
                        >
                            <img src={ thumbnail.imageSrc }  alt={ thumbnail.description } loading={(index < 3) ? "eager" : "lazy"}></img>
                        </div>
                    )}
                </div>
                <button onClick={ () => slideCarousel(1) }><FaCaretRight /></button>
            </div>
            <p id="scroll-hint" ref={ scrollHintRef }>Scroll for more <FaArrowRight /></p>
        </>
    )
}