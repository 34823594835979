import '../styles/Episodes.css';
import video from '../assets/Website Background Master4.webm';
import EpisodesList from "../data/episodes.json";
import Episode from "../components/Episode";
import { useState } from 'react';
import { IoCaretDown } from "react-icons/io5";

export default function Episodes() {
  const episodes = EpisodesList.episodes;
  const [episode, setEpisode] = useState(episodes[0]);
  const [dropdownActive, setDropdownActive] = useState(false);

  const dropdownClick = () => {
    setDropdownActive(!dropdownActive);
    console.log(dropdownActive);
  }

  const handleClick = (e) => {
    e.preventDefault();
    setEpisode(episodes[e.target.value]);
    window.jwplayer().playlistItem(e.target.value);
    setDropdownActive(!dropdownActive);
  }

  function EpisodeSelectors() {

    const buttons = episodes.map((button, index) => {
      return <button 
        key={index}
        className={episodes.indexOf(episode) === index ? "active-episode" : "" } 
        value={index} 
        onClick={handleClick}>
        {button.episode}
      </button>
    });

    return (
      <>
        <video id="interview-background" autoPlay loop muted>
          <source src={video} type="video/mp4"></source>
        </video>
        <h1 id="episode-title">Episode Previews</h1>
        <div id="episode-selector">
          { buttons }
        </div>
        <div id="episode-selector-mobile">
          <button id="current-selector" onClick={ dropdownClick }>{episode.episode}<IoCaretDown /></button>
          <div id="episode-selector-dropdown" className={ dropdownActive ? "active-dropdown" : "" }>
            { buttons }
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <EpisodeSelectors />
      <Episode {...episode} />
    </>
  );
}