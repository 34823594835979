export default function Links(props) {
    return(
        <ul id={props.id}>
            <li><a href="/episodes">Episode Previews</a></li>
            <li><a href="/behind-the-scenes">Behind The Scenes</a></li>
            <li><a href="/meet-the-cast">Meet The Cast</a></li>
            <li><a href="/gallery">Gallery</a></li>
            <li><a href="/where-to-watch">Where to Watch</a></li>
        </ul>
    );
}