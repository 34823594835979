import '../styles/Hero.css';
import video from '../assets/Website Background Master4.webm';
// import poster from '../assets/EmergePoster.webp';
import { useRef } from 'react';

function Hero() {
  const lightboxRef = useRef();
  let lightboxActive = false;

  const handleClick = (e) => {
    e.preventDefault();
    lightboxActive = !lightboxActive;
    if (lightboxActive) { lightboxRef.current.style.display = "flex" }
    else { lightboxRef.current.style.display = "none" }
  }

  return (
    <div id="hero-container">
      <div id="hero-background">
        <video id="hero-video" autoPlay loop muted>
          <source src={video} type="video/mp4"></source>
        </video>
      </div>
      <div id="hero-foreground">
        <div id="hero-top">
          {/* <img alt="Emerge Poster" src={poster}></img> */}
          <div>
            <h1>EMERGE</h1>
            <h2 id="tagline">It's not your ordinary hospital.</h2>
          </div>
        </div>
        <div id="video-container">
          <div style={{padding: "56.25% 0 0 0", position : "relative"}}>
              <iframe src="https://player.vimeo.com/video/871222956?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" style={{ position: "absolute" , top: 0, left: 0 ,width: "100%",height: "100%"}} title="Emerge_Trailer"></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
