import VideoPlayer from './VideoPlayer';

function Episode(props) {
  return (
    <div id="episode-container">
        <div id="trailer-container">
          <VideoPlayer video={ props.video } />
        </div>
        <div id="synopsis">
            <h1>{props.title}</h1>
            <p>{props.synopsis}</p>
        </div>
    </div>
  );
}

export default Episode;
