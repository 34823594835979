import VIVA_LOGO from "../assets/VIVA-logo.webp";
import TLN_LOGO from "../assets/tln-logo.webp";
import '../styles/Hero.css';
import video from '../assets/Website Background Master4.webm';

export default function Watch() {
    return(
        <div id="hero-container">
            <div id="hero-background">
                <video id="hero-video" autoPlay loop muted>
                    <source src={video} type="video/mp4"></source>
                </video>
            </div>
            <div id="hero-foreground">
                <div id="watch">
                    <h2>Coming soon to</h2>
                    <p><a href="https://www.tln.ca/"><img src={ TLN_LOGO }></img></a> and <a href="https://landing.vivatv.ca/"><img src={ VIVA_LOGO }></img></a></p>
                </div>
              </div>
        </div>
    )
}